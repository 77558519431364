import Axios from 'axios'

import { TrafficAPI } from './traffic.api'
import { OSRMAPI } from './osrm.api'
import { CDDAPI } from './cdd.api'
import { DocumentsAPI } from './pdf.api'

export class FinderAPI {
  constructor(private readonly rewriteProps: IWidgetProps) {
    const axiosInstance = Axios.create({
      baseURL: this.getBaseURL(),
    })
    this.cdd = new CDDAPI(axiosInstance)
    this.traffic = new TrafficAPI(axiosInstance)
    this.documents = new DocumentsAPI(axiosInstance)
    this.osrm = new OSRMAPI(Axios.create({ baseURL: this.getOsrmURL() }))
  }

  private getBaseURL(): string {
    return this.rewriteProps.apiBase
  }

  private getOsrmURL(): string {
    return this.rewriteProps.osrm
  }

  traffic: TrafficAPI
  osrm: OSRMAPI
  documents: DocumentsAPI
  cdd: CDDAPI
}
