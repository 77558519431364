
import Vue from 'vue'
import { mapStateTyped } from '../store'
import L from 'leaflet'

import Map from '@/components/map.vue'
import SearchBar from '@/components/searchbar.vue'
import { time } from '../plugins/managed-time'
import moment from 'moment-timezone'
import { momentFilter } from '@/utils'
import WidgetAdapter from '@/components/common/WidgetAdapter.vue'

const MONTH = 2592000

export default Vue.extend({
  name: 'Search',
  components: {
    Map,
    SearchBar,
    WidgetAdapter,
  },
  inject: ['rewriteProps'],
  data() {
    return {
      routes: [] as (
        | number[]
        | {
            image: string
            // eslint-disable-next-line camelcase
            license_plate: number
            model: string
            datetime: string
            position: number[]
            country: string
            kind: string
            uuid: string
            color: string
          }
      )[],
      lastItem: null,
      resultName: '',
      params: {},
      currentItem: {} as any,
      openPhoto: false,
      requiredRule: (value: string) => !!value || 'Поле не может быть пустым.',
      numberCurrentItem: 0,
      hideSavingButton: false,
      isLongResponse: false,
      photoOrigin: '',
      photoBaseUrl: '',
      timezone: '',
    }
  },

  computed: {
    ...mapStateTyped([
      'api',
      'sourceList',
      'isTrafficListLoading',
      'isSourceListLoading',
      'trafficList',
      'isRouteAndShowLoading',
      'rawTrafficList',
    ]),
    routePredefinedParameters() {
      return () => {
        return {
          routeFinderTemplate: {
            // @ts-ignore
            props: this.rewriteProps,
            events: this.rawTrafficList.slice(0, 160).map((item: any) => {
              return {
                datetime: item.datetime,
                image: this.getImageUrl(item.image),
                location: {
                  latitude: item.location.latitude,
                  longitude: item.location.longitude,
                },
                license_plate: item.vehicle.license_plate,
                model: item.vehicle.model.name,
                country: item.vehicle.country.description,
                kind: item.vehicle.kind.description,
                uuid: item.uuid,
                color: item.vehicle.color.description,
              }
            }),
          },
        }
      }
    },
    passagePredefinedParameters() {
      return () => {
        this.openPhoto = false
        return {
          passageFinderTemplate: {
            // @ts-ignore
            props: this.rewriteProps,
            event: {
              license_plate: this.currentItem.license_plate,
              color: this.currentItem.color,
              country: this.currentItem.country,
              datetime: this.currentItem.datetime,
              image: this.getImageUrl(this.currentItem.photos[this.numberCurrentItem]?.src),
              kind: this.currentItem.kind,
              location: {
                latitude: this.currentItem.latitude,
                longitude: this.currentItem.longitude,
              },
              model: this.currentItem.model,
              uuid: this.currentItem.uuid,
            },
          },
        }
      }
    },
  },

  async mounted() {
    if (!this.sourceList.length && !this.isSourceListLoading) {
      this.$store.dispatch('loadSourceList')
    }
    // @ts-ignore
    this.photoOrigin = this.rewriteProps.photoOrigin
    // @ts-ignore
    this.photoBaseUrl = this.rewriteProps.photoBaseUrl
    // @ts-ignore
    this.timezone = this.rewriteProps.tz
  },
  beforeDestroy() {
    this.$store.commit('reset')
  },
  methods: {
    search({ from, to, number }: any) {
      this.currentItem = {}
      this.routes = []
      this.$store.commit('setValue', ['trafficList', []])

      const fromFormated = moment(from.getTime()).format('YYYY-MM-DDTHH:mm:00')
      const toFormated = moment(to.getTime()).format('YYYY-MM-DDTHH:mm:00')

      this.params = {
        license_plate: number,
        search_start_datetime: moment(fromFormated).tz(this.timezone).format(),
        search_end_datetime: moment(toFormated).tz(this.timezone).format(),
      }
      if ((to.getTime() - from.getTime()) / 1000 > MONTH) {
        this.$store.commit('setValue', [
          'lastRequestResult',
          {
            text: 'Нельзя выбрать больше 1 месяца!',
            color: 'info',
          },
        ])
        return
      }
      if (from.getTime() >= to.getTime()) {
        this.$store.commit('setValue', [
          'lastRequestResult',
          {
            text: 'Дата начала поиска должна быть раньше даты окончания поиска',
            color: 'info',
          },
        ])
        return
      }
      this.$store.dispatch('loadTrafficList', this.params)
    },
    getImage(src: string) {
      return `${this.photoOrigin}/${src}`
    },

    getImageUrl(imageName: string) {
      return `${this.photoBaseUrl}${imageName}`
    },
    clear() {
      this.$store.commit('setValue', ['trafficList', []])
      this.currentItem = {}
      this.routes = []
    },
    loadRouteAndShow(item: any) {
      this.$store.commit('setValue', ['isRouteAndShowLoading', true])
      const list = item.traffic_list as Array<TrafficResponseItem>
      const waypoints = list.map((ti) => ({
        latitude: ti.location.latitude,
        longitude: ti.location.longitude,
      }))
      this.lastItem = item

      if (item.paused) {
        item.paused = false
        item.playing = true
        time.resume()
        this.$forceUpdate()
        return
      }

      this.trafficList.forEach((tl: any) => {
        tl.stopped = tl.paused = tl.playing = false
      })
      item.playing = true
      time.reset()

      if (waypoints.length > 1) {
        this.api?.osrm?.getRoute(waypoints)?.then((path: number[][]) => {
          const routes = []
          this.$store.commit('setValue', ['isRouteAndShowLoading', false])

          for (let index = 0; index < list.length; index++) {
            const element = list[index]
            routes.push(
              {
                image: element.image,
                license_plate: element.license_plate,
                model: element.model,
                datetime: element.datetime,
                position: [element.location.latitude, element.location.longitude],
                country: element.country,
                kind: element.kind,
                uuid: element.uuid,
                color: element.color,
              },
              path[index],
            )
          }
          this.routes = routes.filter((x) => x)
        })
      } else {
        this.routes = [
          {
            image: list[0].image,
            license_plate: list[0].license_plate,
            datetime: list[0].datetime,
            model: list[0].model,
            position: [waypoints[0].latitude, waypoints[0].longitude],
            country: list[0].country,
            kind: list[0].kind,
            uuid: list[0].uuid,
            color: list[0].color,
          },
          [
            L.latLng(waypoints[0].latitude, waypoints[0].longitude),
            L.latLng(waypoints[0].latitude, waypoints[0].longitude),
          ] as number[],
        ]
      }
    },
    pause(item: any) {
      this.lastItem = item

      item.paused = true
      item.playing = false
      item.stopped = false

      time.pause()
      this.$forceUpdate()
    },
    stop(item?: any) {
      this.$store.commit('setValue', ['isRouteAndShowLoading', false])

      if (!item) item = this.lastItem
      this.lastItem = item

      item.paused = false
      item.playing = false
      item.stopped = true

      time.isStopped = true

      this.routes = [...this.routes]
      this.$forceUpdate()
    },
    closeDialog() {
      this.openPhoto = false
      this.numberCurrentItem = 0
      this.currentItem = {}
    },
    setCurrentItem(item: any) {
      this.numberCurrentItem = 0
      const photos = this.routes
        .filter((it: any) => !Array.isArray(it))
        .filter((it: any) => JSON.stringify(it.position) === JSON.stringify(item.position))
        .map((it: any) => ({
          src: it.image,
          datetime: it.datetime,
          uuid: it.uuid,
        }))

      const curIndex = photos.findIndex((it: any) => it.datetime === item.datetime)
      if (curIndex !== 0) {
        ;[photos[0], photos[curIndex]] = [photos[curIndex], photos[0]]
      }

      this.currentItem = {
        license_plate: item.license_plate.toUpperCase(),
        datetime: item.datetime,
        image: item.image && item.image.length ? item.image : '',
        latitude: item.position[0],
        longitude: item.position[1],
        photos: photos.map((ph: any) => ({ ...ph, datetime: momentFilter(ph.datetime) })),
        model: item.model,
        country: item.country,
        kind: item.kind,
        uuid: item.uuid,
        color: item.color,
      }
      this.openPhoto = true
    },
  },
})
