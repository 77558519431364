
import Vue from 'vue'
import { mapMutationsTyped, mapStateTyped } from './store'
import { environment } from './environment'
import Search from './views/search.vue'
import { FinderAPI } from './api'

export default Vue.extend({
  components: {
    search: Search,
  },
  provide(): any {
    return {
      spaParent: this.$parent,
      rewriteProps: { ...this.rewriteProps } as IWidgetProps,
    }
  },
  data() {
    console.log('rewriteProps', this.$parent?.props?.rewriteProps)
    const rewriteProps: IWidgetProps = {
      photoBaseUrl: 'http://10.25.8.12/gateway/finder-photo/',
      photoOrigin: '/gateway/finder-photo/',
      pdfUrl: 'http://192.168.166.15:9000',
      osm: 'http://10.25.8.12:8087/tile/{z}/{x}/{y}.png',
      center: '[53.1966, 50.1666]',
      zoom: 14,
      tz: 'Europe/Samara',
      apiBase: '/gateway/finder',
      osrm: 'http://10.25.2.51:5000',
      ...(this.$parent?.props?.rewriteProps || {}),
    }
    return {
      rewriteProps,
      isDark: false,
      themeUnsubscribe: () => {},
    }
  },
  computed: {
    ...mapStateTyped(['lastRequestResult', 'isDarkTheme']),
    isSnackbarOpen: {
      get(): any {
        return !!this.lastRequestResult
      },
      set(value): any {
        if (!value) {
          this.$store.commit('setValue', ['lastRequestResult', null])
        }
      },
    },
    snackbarColor(): any {
      return this.lastRequestResult ? this.lastRequestResult.color : 'info'
    },
    snackbarText(): any {
      return this.lastRequestResult ? this.lastRequestResult.text : ''
    },
  },
  beforeMount() {
    this.$store.commit('setValue', ['api', new FinderAPI(this.rewriteProps)])
  },
  created() {
    const themeEl = document.getElementById('theme') as HTMLLinkElement & {
      setTheme: () => void
    }
    this.setValue(['isDarkTheme', themeEl.getAttribute('theme-name') === 'dark'])
    const themeSubscribe = (func: (newValue: boolean) => void) => {
      const listener = (e: any) => func(e.detail === 'dark')
      themeEl.addEventListener('update', listener)
      return () => themeEl.removeEventListener('update', listener)
    }

    this.themeUnsubscribe = themeSubscribe((newValue) => {
      this.setValue(['isDarkTheme', newValue])
    })
    this.$vuetify.lang.current = environment.lang
  },
  destroyed() {
    this.themeUnsubscribe()
  },
  methods: {
    ...mapMutationsTyped(['setValue']),
  },
})
