/* eslint-disable no-tabs */
import L from 'leaflet'
import { time } from './managed-time'

/// // FIXME: Use path._rings instead of path._latlngs???
/// // FIXME: Panic if this._map doesn't exist when called.
/// // FIXME: Implement snakeOut()
/// // FIXME: Implement layerGroup.snakeIn() / Out()

L.Polyline.include({
  // Hi-res timestamp indicating when the last calculations for vertices and
  // distance took place.
  _snakingTimestamp: 0,

  // How many rings and vertices we've already visited
  // Yeah, yeah, "rings" semantically only apply to polygons, but L.Polyline
  // internally uses that nomenclature.
  _snakingRings: 0,
  _snakingVertices: 0,

  // Distance to draw (in screen pixels) since the last vertex
  _snakingDistance: 0,

  // Flag
  _snaking: false,

  /// TODO: accept a 'map' parameter, fall back to addTo() in case
  /// performance.now is not available.
  snakeIn: function () {
    if (this._snaking) {
      return
    }

    if (!this._map) {
      return
    }

    this._snaking = true
    this._snakingTime = time.now()
    this._snakingVertices = this._snakingRings = this._snakingDistance = 0

    if (!this._snakeLatLngs) {
      this._snakeLatLngs = L.LineUtil.isFlat(this._latlngs) ? [this._latlngs] : this._latlngs
    }

    // Init with just the first (0th) vertex in a new ring
    // Twice because the first thing that this._snake is is chop the head.
    this._latlngs = [[this._snakeLatLngs[0][0], this._snakeLatLngs[0][0]]]
    this._circle = L.circle(this._snakeLatLngs[0][0], { radius: 30 })
    this._circle.addTo(this._map)
    // this._map.setView(this._snakeLatLngs[0][0], 14)

    this._update()
    this._snake()
    this.fire('snakestart')
    return this
  },

  _snake: function () {
    const now = time.now()
    const diff = now - this._snakingTime // In milliseconds
    const forward = (diff * this.options.snakingSpeed) / 1000 // In pixels
    this._snakingTime = now

    // Chop the head from the previous frame
    this._latlngs[this._snakingRings].pop()

    return this._snakeForward(forward)
  },

  _snakeForward: function (forward: any) {
    // If polyline has been removed from the map stop _snakeForward
    if (!this._map) return
    // Calculate distance from current vertex to next vertex
    const currPoint = this._map.latLngToContainerPoint(
      this._snakeLatLngs[this._snakingRings][this._snakingVertices],
    )
    const nextPoint = this._map.latLngToContainerPoint(
      this._snakeLatLngs[this._snakingRings][this._snakingVertices + 1],
    )

    const distance = currPoint.distanceTo(nextPoint)

    // 		console.log('Distance to next point:', distance, '; Now at: ', this._snakingDistance, '; Must travel forward:', forward);
    // 		console.log('Vertices: ', this._latlngs);

    if (this._snakingDistance + forward > distance) {
      // Jump to next vertex
      this._snakingVertices++
      this._latlngs[this._snakingRings].push(
        this._snakeLatLngs[this._snakingRings][this._snakingVertices],
      )

      if (this._snakingVertices >= this._snakeLatLngs[this._snakingRings].length - 1) {
        if (this._snakingRings >= this._snakeLatLngs.length - 1) {
          return this._snakeEnd()
        } else {
          this._snakingVertices = 0
          this._snakingRings++
          this._latlngs[this._snakingRings] = [
            this._snakeLatLngs[this._snakingRings][this._snakingVertices],
          ]
        }
      }

      this._snakingDistance -= distance
      return this._snakeForward(forward)
    }

    this._snakingDistance += forward
    const percent = this._snakingDistance / distance

    const headPoint = nextPoint.multiplyBy(percent).add(currPoint.multiplyBy(1 - percent))

    // Put a new head in place.
    const headLatLng = this._map.containerPointToLatLng(headPoint)
    this._latlngs[this._snakingRings].push(headLatLng)

    this._map.setView(headLatLng, 14)
    this.setLatLngs(this._latlngs)
    this._circle.setLatLng(headLatLng)

    this.fire('snake')
    L.Util.requestAnimFrame(this._snake, this)
  },

  _snakeEnd: function () {
    this._map.removeLayer(this._circle)
    this.setLatLngs(this._snakeLatLngs)
    this._snaking = false
    this.fire('snakeend')
  },
})

L.Polyline.mergeOptions({
  snakingSpeed: 200, // In pixels/sec
})

L.LayerGroup.include({
  _snakingLayers: [],
  _snakingLayersDone: 0,

  snakeIn: function () {
    if (!this._map || this._snaking) {
      return
    }

    this._snaking = true
    this._snakingLayers = []
    this._snakingLayersDone = 0

    const keys = Object.keys(this._layers)

    for (const i in keys) {
      const key = keys[i]
      this._snakingLayers.push(this._layers[key])
    }

    this.clearLayers()

    this.fire('snakestart')
    return this._snakeNext()
  },

  _snakeNext: function () {
    if (this._snakingLayersDone >= this._snakingLayers.length) {
      this.fire('snakeend')
      this._snaking = false
      return
    }

    const currentLayer = this._snakingLayers[this._snakingLayersDone]
    this._snakingLayersDone++
    this.addLayer(currentLayer)

    if ('snakeIn' in currentLayer) {
      currentLayer.once(
        'snakeend',
        function (this: any) {
          setTimeout(this._snakeNext.bind(this), this.options.snakingPause)
        },
        this,
      )

      currentLayer.snakeIn()
    } else {
      setTimeout(this._snakeNext.bind(this), this.options.snakingPause)
    }

    this.fire('snake')
    currentLayer && currentLayer.getLatLng && this._map.setView(currentLayer.getLatLng(), 14)

    return this
  },

  snakeStop: function () {
    const currentLayer = this._snakingLayers[this._snakingLayersDone - 1]
    if (currentLayer && 'snakeIn' in currentLayer) {
      currentLayer._snakeEnd()
    }
  },
})

L.LayerGroup.mergeOptions({
  snakingPause: 200,
})
